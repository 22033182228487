import React from 'react';
import logo from '../logo.svg';
import '../styles/App.scss';
import { Header } from './Header';
import { theme } from './Theme';
import { ThemeProvider } from '@mui/material/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header></Header>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Fox Timbers LLC
          </p>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
