import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import "../styles/Header.scss";

export function Header() {
    return <div className="header">
        <AppBar position="static" color="secondary">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="menu"                    
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Fox Timbers
                </Typography>
            </Toolbar>
        </AppBar>
    </div>;
};